<template>
  <UButton :block="block" :color="color" :disabled="disabled" :label="label" :loading="loading" :padded="padded"
    :size="size" :target="target" :truncate="truncate" :type="type" :variant="themeVariant" :ui="uiStyles"
    v-bind="{ ...$attrs }">
    <template v-if="$slots.leading" #leading>
      <slot name="leading" />
    </template>
    <slot />
    <template v-if="$slots.trailing" #trailing>
      <slot name="trailing" />
    </template>
  </UButton>
</template>

<script setup>
const styles = {
  favoritely: {
    primary: {
      variant: {
        solid: 'bg-[#EA1662] text-white text-center ' +
          'dark:bg-pink-500 dark:text-white ' +
          'hover:text-white hover:bg-pink-800 dark:hover:bg-pink-600 ' +
          'disabled:bg-[#EA1662] disabled:text-white dark:disabled:bg-[#F2699B] dark:disabled:text-[#FFFFFF]'
      }
    },
    secondary: {
      variant: {
        solid: 'bg-[#FBEFF3] text-[#EA1662] ' +
          'dark:bg-[#2B3236] dark:text-[#F2699B] ' +
          'hover:text-gray-600 hover:bg-[#e4d8dc] dark:hover:bg-[#394247] dark:hover:text-white ' +
          'disabled:bg-[#FBEFF3] disabled:text-[#EA1662] dark:disabled:bg-[#394247] dark:disabled:text-[#F2699B]',
      }
    },
    tertiary: {
      variant: {
        solid: 'font-bold bg-[#FBEFF3] text-[#595858] ' +
          'dark:bg-[#020303] dark:text-white ' +
          'hover:text-gray-800 dark:hover:bg-[#394247] dark:hover:text-white ' +
          'disabled:bg-[#FBEFF3] disabled:text-[#595858] dark:disabled:!bg-[#394247] dark:disabled:!text-[#F2699B]',
      }
    },
    link: {
      variant: {
        link: 'underline underline-offset-4 ' +
          'text-[#EA1662] hover:text-pink-800 ' +
          'dark:text-[#f26998] dark:hover:text-[#ea1662] ' +
          'hover:underline focus-visible:ring-0 ' +
          'disabled:text-[#828282] dark:disabled:text-[#4B575D]'
      }
    },
    ghost: {
      variant: {
        ghost: 'text-[#2F2E2E] bg-transparent hover:bg-[#FBEFF3] ' +
          'dark:text-white dark:hover:bg-[#2B3236] ' +
          'focus-visible:ring-0 ' +
          'disabled:text-[#828282] dark:disabled:text-[#4B575D]'
      }
    },
    outline: {
      variant: {
        outline: 'ring-1 ring-inset ring-current ring-[#E4E4E4] text-[#595858] ' +
          'hover:text-white hover:ring-0 hover:bg-pink-600 ' +
          'dark:ring-[#2B3236] dark:hover:ring-0 dark:text-[#555F63] dark:hover:text-white dark:hover:bg-pink-500 ' +
          'disabled:text-[#E4E4E4] dark:disabled:text-[#555F63]'
      }
    },
    icon: {
      variant: {
        outline: 'ring-1 ring-inset ring-current ring-[#E4E4E4]' +
          'text-[#595858] hover:text-white hover:ring-0 hover:bg-[#f26998] ' +
          'focus-visible:ring-gray-400 dark:focus-visible:ring-pink-500 ' +
          'dark:ring-[#2B3236] dark:hover:ring-0 dark:text-[#555F63] dark:hover:text-white dark:hover:bg-[#f26998] ' +
          'disabled:text-[#E4E4E4] dark:disabled:text-[#555F63]'
      }
    }
  }
}

const props = defineProps({
  block: {
    type: Boolean,
    default: false
  },
  iconOnly: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: null
  },
  loading: {
    type: Boolean,
    default: false
  },
  padded: {
    type: Boolean,
    default: true
  },
  shape: {
    type: String,
    default: 'rounded'
  },
  size: {
    type: String,
    default: 'md'
  },
  target: {
    type: String,
    default: null
  },
  theme: {
    type: String,
    default: 'primary'
  },
  truncate: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'button'
  },
  variant: {
    type: String,
    default: 'solid'
  }
})

const themeVariant = computed(() => {
  if (['primary', 'secondary', 'tertiary'].includes(props.theme)) {
    return 'solid'
  } else if (['link'].includes(props.theme)) {
    return 'link'
  } else if (['outline', 'icon'].includes(props.theme)) {
    return 'outline'
  } else if (['ghost'].includes(props.theme)) {
    return 'ghost'
  }
})

const uiStyles = computed(() => {
  if (props.shape === 'rounded') {
    styles.favoritely[props.theme].rounded = 'rounded-full'
  } else if (props.shape === 'square') {
    styles.favoritely[props.theme].rounded = 'rounded-md'
  } else {
    styles.favoritely[props.theme].rounded = 'rounded-lg'
  }

  styles.favoritely[props.theme]['base'] = 'focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-1 flex-shrink-0'

  if (props.iconOnly) {
    styles.favoritely[props.theme]['padding'] = {
      '2xs': 'px-1 py-1',
      xs: 'px-2 py-2',
      sm: 'px-2.5 py-2.5',
      md: 'px-3 py-3',
      lg: 'px-3.5 py-3.5',
      xl: 'px-4 py-4',
    }
  } else {
    styles.favoritely[props.theme]['padding'] = {
      '2xs': 'px-3 py-1',
      xs: 'px-3.5 py-1.5',
      sm: 'px-3.5 py-1.5',
      md: 'px-6 py-2',
      lg: 'px-8 py-2.5',
      xl: 'px-8 py-2.5',
    }
  }

  styles.favoritely[props.theme]['size'] = {
    '2xs': 'text-xs',
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-sm',
    lg: 'text-sm',
    xl: 'text-base',
  }

  return styles.favoritely[props.theme]
})
</script>
